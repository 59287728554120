@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$ls-icon-arrow-right: "\EA01"
$ls-icon-arrow-left: "\EA02"
$ls-icon-breadcrumb-separate: "\EA03"
$ls-icon-marker: "\EA04"
$ls-icon-user: "\EA05"
$ls-icon-arrow-right2: "\EA06"
$ls-icon-play: "\EA07"
$ls-icon-search: "\EA08"
$ls-icon-cross: "\EA09"
$ls-icon-burger: "\EA10"
$ls-icon-user-2: "\EA11"
$ls-icon-dropdown-arrow: "\EA12"
$ls-icon-marker-2: "\EA13"
$ls-icon-phone: "\EA14"
$ls-icon-time: "\EA15"
$ls-icon-mail: "\EA16"
$ls-icon-calendar: "\EA17"
$ls-icon-attach: "\EA18"
$ls-icon-user-3: "\EA19"
$ls-icon-check: "\EA20"
$ls-icon-rounded-arrow: "\EA21"
$ls-icon-calendar-2: "\EA22"
$ls-icon-card: "\EA23"
$ls-icon-star: "\EA24"
$ls-icon-fire: "\EA25"
$ls-icon-bag: "\EA26"
$ls-icon-checkmark: "\EA27"
$ls-icon-cross2: "\EA28"
$ls-icon-eye-off: "\EA29"
$ls-icon-eye-on: "\EA30"
$ls-icon-arrow-left-circle: "\EA31"
$ls-icon-layout: "\EA32"
$ls-icon-grid: "\EA33"
$ls-icon-bell: "\EA34"
$ls-icon-sliders: "\EA35"
$ls-icon-edit: "\EA36"
$ls-icon-book-open: "\EA37"
$ls-icon-wallet: "\EA38"
$ls-icon-clipboard-list: "\EA39"
$ls-icon-file: "\EA40"
$ls-icon-list: "\EA41"
$ls-icon-play2: "\EA42"
$ls-icon-clip: "\EA43"
$ls-icon-trash: "\EA44"
$ls-icon-thumbs-up: "\EA45"
$ls-icon-checkmark-outline: "\EA46"
$ls-icon-arrow-up-circle: "\EA47"
$ls-icon-arrow-down-circle: "\EA48"
$ls-icon-exit: "\EA49"
$ls-icon-arrow-right-circle: "\EA50"
$ls-icon-download: "\EA51"
$ls-icon-users: "\EA52"
$ls-icon-clear-filter: "\EA53"
$ls-icon-dots: "\EA54"
$ls-icon-orders: "\EA55"
$ls-icon-right-chevron: "\EA56"
$ls-icon-left-chevron: "\EA57"
$ls-icon-alert: "\EA58"
$ls-icon-refresh: "\EA59"
$ls-icon-dropdown: "\EA60"
$ls-icon-hint: "\EA61"
$ls-icon-twitter: "\EA62"
$ls-icon-facebook: "\EA63"
$ls-icon-viber: "\EA64"
$ls-icon-telegram: "\EA65"
$ls-icon-whatsapp: "\EA66"
$ls-icon-up-chevron: "\EA67"
$ls-icon-pin: "\EA68"


%ls-icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.ls-icon, [class^="icon-"], [class*=" icon-"]
	@extend %ls-icon
	display: inline-block

.ls-icon--type_arrow-right:before
	content: $ls-icon-arrow-right
.ls-icon--type_arrow-left:before
	content: $ls-icon-arrow-left
.ls-icon--type_breadcrumb-separate:before
	content: $ls-icon-breadcrumb-separate
.ls-icon--type_marker:before
	content: $ls-icon-marker
.ls-icon--type_user:before
	content: $ls-icon-user
.ls-icon--type_arrow-right2:before
	content: $ls-icon-arrow-right2
.ls-icon--type_play:before
	content: $ls-icon-play
.ls-icon--type_search:before
	content: $ls-icon-search
.ls-icon--type_cross:before
	content: $ls-icon-cross
.ls-icon--type_burger:before
	content: $ls-icon-burger
.ls-icon--type_user-2:before
	content: $ls-icon-user-2
.ls-icon--type_dropdown-arrow:before
	content: $ls-icon-dropdown-arrow
.ls-icon--type_marker-2:before
	content: $ls-icon-marker-2
.ls-icon--type_phone:before
	content: $ls-icon-phone
.ls-icon--type_time:before
	content: $ls-icon-time
.ls-icon--type_mail:before
	content: $ls-icon-mail
.ls-icon--type_calendar:before
	content: $ls-icon-calendar
.ls-icon--type_attach:before
	content: $ls-icon-attach
.ls-icon--type_user-3:before
	content: $ls-icon-user-3
.ls-icon--type_check:before
	content: $ls-icon-check
.ls-icon--type_rounded-arrow:before
	content: $ls-icon-rounded-arrow
.ls-icon--type_calendar-2:before
	content: $ls-icon-calendar-2
.ls-icon--type_card:before
	content: $ls-icon-card
.ls-icon--type_star:before
	content: $ls-icon-star
.ls-icon--type_fire:before
	content: $ls-icon-fire
.ls-icon--type_bag:before
	content: $ls-icon-bag
.ls-icon--type_checkmark:before
	content: $ls-icon-checkmark
.ls-icon--type_cross2:before
	content: $ls-icon-cross2
.ls-icon--type_eye-off:before
	content: $ls-icon-eye-off
.ls-icon--type_eye-on:before
	content: $ls-icon-eye-on
.ls-icon--type_arrow-left-circle:before
	content: $ls-icon-arrow-left-circle
.ls-icon--type_layout:before
	content: $ls-icon-layout
.ls-icon--type_grid:before
	content: $ls-icon-grid
.ls-icon--type_bell:before
	content: $ls-icon-bell
.ls-icon--type_sliders:before
	content: $ls-icon-sliders
.ls-icon--type_edit:before
	content: $ls-icon-edit
.ls-icon--type_book-open:before
	content: $ls-icon-book-open
.ls-icon--type_wallet:before
	content: $ls-icon-wallet
.ls-icon--type_clipboard-list:before
	content: $ls-icon-clipboard-list
.ls-icon--type_file:before
	content: $ls-icon-file
.ls-icon--type_list:before
	content: $ls-icon-list
.ls-icon--type_play2:before
	content: $ls-icon-play2
.ls-icon--type_clip:before
	content: $ls-icon-clip
.ls-icon--type_trash:before
	content: $ls-icon-trash
.ls-icon--type_thumbs-up:before
	content: $ls-icon-thumbs-up
.ls-icon--type_checkmark-outline:before
	content: $ls-icon-checkmark-outline
.ls-icon--type_arrow-up-circle:before
	content: $ls-icon-arrow-up-circle
.ls-icon--type_arrow-down-circle:before
	content: $ls-icon-arrow-down-circle
.ls-icon--type_exit:before
	content: $ls-icon-exit
.ls-icon--type_arrow-right-circle:before
	content: $ls-icon-arrow-right-circle
.ls-icon--type_download:before
	content: $ls-icon-download
.ls-icon--type_users:before
	content: $ls-icon-users
.ls-icon--type_clear-filter:before
	content: $ls-icon-clear-filter
.ls-icon--type_dots:before
	content: $ls-icon-dots
.ls-icon--type_orders:before
	content: $ls-icon-orders
.ls-icon--type_right-chevron:before
	content: $ls-icon-right-chevron
.ls-icon--type_left-chevron:before
	content: $ls-icon-left-chevron
.ls-icon--type_alert:before
	content: $ls-icon-alert
.ls-icon--type_refresh:before
	content: $ls-icon-refresh
.ls-icon--type_dropdown:before
	content: $ls-icon-dropdown
.ls-icon--type_hint:before
	content: $ls-icon-hint
.ls-icon--type_twitter:before
	content: $ls-icon-twitter
.ls-icon--type_facebook:before
	content: $ls-icon-facebook
.ls-icon--type_viber:before
	content: $ls-icon-viber
.ls-icon--type_telegram:before
	content: $ls-icon-telegram
.ls-icon--type_whatsapp:before
	content: $ls-icon-whatsapp
.ls-icon--type_up-chevron:before
	content: $ls-icon-up-chevron
.ls-icon--type_pin:before
	content: $ls-icon-pin
